<template>
    <router-view />
</template>
<script>

</script>
<style lang="scss">
@media screen and (max-width:600px) {
    //分页
    .el-pagination{
        flex-wrap: wrap;
        justify-content: center;
    }
    .el-pagination__sizes{
        width: 100%;
        text-align: center;
    }
    .btn-prev{
        margin-left: 0 !important;
    }
    .el-form--inline .el-form-item{
        margin-right: 0;
    }
    //弹框
    
    .el-dialog {
        width: 90% !important;
    }
    //日期弹框
    .el-date-range-picker{
        width: 300px;
        .el-picker-panel__body{
            width: 300px;
            min-width: 0;
            .el-date-range-picker__content{
                width: 100% !important;
            }
        }
    }
    //场景弹框
    .el-select-dropdown{
        max-width: 300px;
    }
    //轮播图按钮
    .el-carousel__arrow{
        display: block !important; 
        top: 60% !important;
    }
    .el-carousel__arrow--left{
        left: 0 !important;
    }
    .el-carousel__arrow--right{
        right: 0 !important;
    }
}

.el-select {
    --el-select-input-focus-border-color: #606266;
}

.el-check-tag {
    padding: 10px 20px;
    --el-color-info-light-9: #ffffff;
    border-radius: 0.5rem;
    font-weight: 500;
    border: 1px solid #909399;
}

.el-check-tag.is-checked {
    --el-color-primary-light-8: #606266;
    --el-color-primary: #ffffff;
}

.el-input {
    --el-input-focus-border-color: #606266;
}

.el-tabs {
    --el-color-primary: #e3306f;
}

.el-range-editor.is-active {
    box-shadow: 0 0 0 1px #606266;
}

.el-form-item__label {
    margin-bottom: 0px !important;
}

.el-picker__popper {
    z-index: 9999 !important;
}

.el-loading-mask {
    border: none !important;
}

.el-button {
    --el-button-hover-link-text-color: #81bfff;
    --el-button-hover-text-color: #606266;
    --el-button-hover-bg-color: #e0e0e0;
    --el-button-hover-border-color: #606266;
}

.el-button--primary {
    --el-button-bg-color: #424245;
    --el-button-border-color: #424245;
    --el-button-hover-text-color: #606266;
    --el-button-hover-bg-color: #e0e0e0;
    --el-button-hover-border-color: #606266;
}

.el-button.is-link {
    --el-button-text-color: #409EFF;
}

.el-radio-button__inner {
    --el-radio-button-checked-bg-color: #424245;
    --el-radio-button-checked-border-color: #424245;
}

.el-form-item__label {
    font-weight: 700;
}

.el-table thead {
    color: #606266 !important;
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
    cursor: default;
}

.el-input-group__prepend {
    cursor: default;
}

.el-input.is-disabled .el-input__inner {
    cursor: default;
}

.el-dialog__title {
    font-weight: 600;
    font-size: 16px;
}

.el-collapse {
    border-top: none;
}

.el-collapse-item:last-child {
    .el-collapse-item__header {
        border-bottom: none;
    }
}</style>
