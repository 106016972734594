<template>
  <nav aria-label="breadcrumb">
    <ol
      class="px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb  me-sm-6"
    >
      <li class="breadcrumb-item" :class="color" >
        <a :class="color" class="opacity-8 font-weight-bolder text-16" href="#">ITT CARD</a>
      </li>
      <li class="breadcrumb-item active font-weight-bolder text-16"
        :class="color"
        aria-current="page"
      >
        {{ currentPage }}
      </li>
    </ol>
    <!-- <h6 class="mb-0 font-weight-bolder" :class="color">
      {{ currentPage }}
    </h6> -->
  </nav>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "breadcrumbs",
  props: {
    currentPage: {
      type: String,
      required: "",
    },
    color: {
      type: String,
      default: "text-dark",
    },
  },
  computed: {
    ...mapState(["isRTL"]),
  },
};
</script>
