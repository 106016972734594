import {
  createStore
} from "vuex";
import persistedStateVuex from "persisted-state-vuex";
import http from "@/api";
import router from "../router";

export default createStore({
  state: {
    token: null,
    user: {},
    permissions: [],
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-gradient-dark",
    isRTL: false,
    color: "primary",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    isDarkMode: false,
    navbarFixed: "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    safeKey: '',
    openMsg: false,
    isOut: false,
    infoTimer: null,
    userMessageType: false,
    messageType: false,
    urgentType: false
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");

      if (sidenav_show.classList.contains("g-sidenav-pinned")) {
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
    color(state, payload) {
      state.color = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    setUser(state, payload) {
      if (payload && !payload.userLevel) payload.userLevel = 0
      state.user = payload;
    },
    setPermissions(state, payload) {
      state.permissions = payload
    },
    getUser(state) {
      if (!state.token) {
        state.userMessageType = false
        this.commit("setMessageType", false)
        router.push({
          name: "SignIn"
        })
      } else http.login.getInfo().then(res => {
        this.commit("setIsOut", false)
        this.commit("setUser", res.user)
        this.commit("setPermissions", res.permissions)
      })
    },
    logOut(state) {
      this.commit("setToken", null)
      this.commit("setUser", {})
      this.commit("setPermissions", [])
      this.commit("setIsOut", false)
      this.commit("setMessageType", false)
      this.commit("setUserMessageType", false)
      this.commit("setUrgentType", false)
      // state.userMessageType = false
      if (state.infoTimer) clearInterval(state.infoTimer)
      router.push({
        name: "SignIn"
      })
    },
    setOpenMsg(state, payload) {
      state.openMsg = payload
    },
    setIsOut(state, payload) {
      state.isOut = payload
    },
    SET_SAFE_KEY: (state, key) => {
      state.safeKey = key
    },
    setMessageType(state, payload) {
      state.messageType = payload
    },
    setUserMessageType(state, payload) {
      state.userMessageType = payload
    },
    setUrgentType(state, payload) {
      state.urgentType = payload
    },
  },
  actions: {
    setSafeKey({
      commit
    }, key) {
      commit('SET_SAFE_KEY', key)
    },
    setColor({
      commit
    }, payload) {
      commit("color", payload);
    },
  },
  getters: {},
  plugins: [persistedStateVuex.init],
});