<template>
  <div class="card bg-transparent shadow-xl card-wrap">
    <div class="overflow-hidden position-relative border-radius-xl" :style="{
      backgroundImage: 'url(' + require('@/assets/img/curved14.jpg') + ')',
    }">
      <span class="mask" :class="cName"></span>
      <div class="card-body position-relative z-index-1">
        <div class="d-flex djustify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <div class="d-flex pr-1" style="width: 20px;height: 20px;min-width: 20px;margin-right: 5px; border-radius: 50%;overflow: hidden;">
              <img class="w-100" :src="require('../../assets/img/logos/' + cardAdd + '.png')" alt="logo" >
            </div>
            <div class="d-flex">
              <text class="text-white font-weight-bolder">{{ cardNum }}</text>
            </div>
          </div>
        </div>
        <div class="d-flex pt-2 justify-content-between align-items-center">
          <div>
            <text class="text-white text-xs" v-if="cardDesc">( {{ cardDesc }} )</text>
          </div>
          <el-image class="w-20" v-if="cardType" :src="brandImgList[ cardType ]" fit="contain" style="vertical-align: middle;height: 24px;"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, toRaw } from "vue";
import { useStore } from "vuex";

const brandImgList = ref({
  "Visa": require("@/assets/img/logos/visa.png"),
  "Master Card": require("@/assets/img/logos/mastercard.png"),
  "UnionPay": require("@/assets/img/logos/UnionPay.png"),
})
const store = useStore()
const isRTL = computed(() => store.state.isRTL);
const props = defineProps({
  cardNum: {
    type: String,
    default: "",
  },
  cardAdd: {
    type: String,
    default: "",
  },
  cardType: {
    type: String,
    default: "",
  },
  cardDesc: {
    type: String,
    default: "",
  },
  cName: {
    type: String,
    default: "",
  },
  card: {
    type: Object,
    number: String,
    holderName: String,
    expiryDate: String,
    holderText: String,
    expiryText: String,
    background: String,
    logo: String,
    default: () => ({
      number: "4562   1122   4594   7852",
      holderName: "Jack Peterson",
      expiryDate: "11/22",
      holderText: "Card Holder",
      expiryText: "MASTER",
      background: "dark",
      logo: ''
    }),
  },
})
const cardBin = ref('')
const cardTis = ref('')
onMounted(() => {
  cardBin.value = toRaw(props).cardNum;
  if(cardBin.value){
    cardTis.value = toRaw(props).cardNum.match(/-(.*)/)
    cardBin.value = cardBin.value.match(/[0-9/]+/g).join('')
  }
})
</script>

<style lang="scss" scoped>
.mask{
  background-image: linear-gradient(195deg, #c9c9c9 0%, #c1c1c1 100%);
  // width: 104%;
  // height: 104%;
  // margin: -2% 0 0 -2%;
}
.card-mask{
  background-image: linear-gradient(195deg, #bcc2cf 0%, #333333 100%);
  // width: 104%;
  // height: 104%;
  // margin: -2% 0 0 -2%;
}
.card-body{
  padding: 1rem !important;
}
@media screen and (max-width: 620px) {
  .card-body{
    padding: 10px !important;
  }
}
</style>
