<template>
  <div class="bg-white">
    <main class="mt-0 main-content">
      <section>
        <div class="page-header min-vh-100">
          <div class="container">
            <div class="row">
              <div
                class="col-6 d-lg-flex d-none h-100 my-auto pe-0 ps-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
                <div class="position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/illustrations/bimg-5.jpg') +
                      ')',
                  }" style="background-repeat: no-repeat;background-position: 80% 30%;background-size: cover;"></div>
              </div>
              <div class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
                <div class="card card-plain">
                  <div class="pb-0 card-header bg-transparent mb-4">
                    <h4 class="font-weight-bolder">
                      <img src="../assets/logo/logo.png" class="w-10"
                        style="margin-right: 10px;margin-top: -4px;">
                      <!-- <img src="../assets/img/login/logo-p.png" class="w-40"> -->
                      ITT CARD
                    </h4>
                    <p class="mb-0">
                      输入您的电子邮件和密码进行注册
                    </p>
                  </div>
                  <div class="card-body">
                    <el-form role="form" :model="registerForm" :rules="registerRules" ref="registerRef">
                      <div class="mb-3">
                        <el-form-item prop="email">
                          <material-input id="email" type="email" label="邮箱" name="email" size="lg"
                            v-model="registerForm.email" />
                        </el-form-item>
                      </div>
                      <div class="mb-3" style="display: flex;">
                        <material-input id="code" link label="邮箱验证码" name="code" size="lg"
                          v-model="registerForm.emailCode" style="width: 63%;" />
                        <material-button color="primary" style="width: 33%;margin-left: 4%;min-width: 120px;"
                          @click="getEmailCode">{{ codeTime ? codeTime + 's' : '获取验证码' }}</material-button>
                      </div>
                      <div class="mb-3">
                        <el-form-item prop="inviteCode">
                          <div class="d-flex align-items-center justify-content-between w-100">
                            <material-input id="password" type="password" label="邀请码" name="password" size="lg"
                              v-model="registerForm.inviteCode" style="width: 45%;" />
                            <!-- <div class="d-flex align-items-center justify-content-center">
                              <el-icon class="mx-2 text-2xl"><Service /></el-icon>
                              <el-link :href="sys_cs_url" target="_blank" type="danger" class="text-xl text-primary">咨询客服,领取邀请码</el-link>
                            </div> -->
                            <material-button color="primary" style="width: 52%;height: 44.19px;min-width: 182px;"
                              @click="openUrl">
                              <div class="d-flex align-items-center justify-content-center">
                                <el-icon class="text-16 ms-0 mx-1">
                                  <Service />
                                </el-icon>咨询客服,领取邀请码
                              </div>
                            </material-button>
                          </div>
                        </el-form-item>
                      </div>
                      <div class="mb-3">
                        <el-form-item prop="password">
                          <material-input id="password" type="password" label="密码" name="password" size="lg"
                            v-model="registerForm.password" />
                        </el-form-item>
                      </div>
                      <div class="mb-3">
                        <el-form-item prop="confirmPassword">
                          <material-input id="password" type="password" label="确认密码" name="password" size="lg"
                            v-model="registerForm.confirmPassword" />
                        </el-form-item>
                      </div>
                      <div class="mb-3" style="display: flex;">
                        <el-form-item prop="code" style="width: 63%;">
                          <material-input id="code" type="number" label="验证码" name="code" v-model="registerForm.code" />
                        </el-form-item>
                        <div class="login-code" style="width: 33%;margin-left: 4%;">
                          <img :src="codeUrl" style="width: 100%;height: 40.19px;" @click="getCode"
                            class="login-code-img" />
                        </div>
                      </div>
                      <material-checkbox id="flexCheckDefault" class="font-weight-light" v-model="ok1">
                        同意使用
                        <router-link class="text-primary" :to="'/clause'" target="_blank">隐私条款</router-link>
                      </material-checkbox>
                      <material-checkbox id="flexCheckDefault" class="font-weight-light" v-model="ok2">
                        <span class="text-primary">仅限在电商平台正常消费，试用/拒付/撸羊毛等行为<br />将会被停用，且不退账户余额，注册既表示同意</span>
                      </material-checkbox>
                      <div class="text-center">
                        <material-button class="mt-4" variant="gradient" color="primary" fullWidth size="lg"
                          @click="handleReg">注
                          册</material-button>
                      </div>
                    </el-form>
                  </div>
                  <div class="px-1 text-center px-lg-2">
                    <p class="mx-auto text-sm">
                      已有账户？
                      <router-link :to="{ name: 'SignIn' }" class="text-primary text-gradient font-weight-bold">登
                        录</router-link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script setup>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import MaterialButton from "@/components/MaterialButton.vue";
const body = document.getElementsByTagName("body")[0];
import { ref, onBeforeMount, onBeforeUnmount, getCurrentInstance, watch } from "vue";
const $app = getCurrentInstance().proxy

const codeUrl = ref()
const registerForm = ref({
  email: "",
  emailCode: "",
  inviteCode: "",
  password: "",
  confirmPassword: "",
  code: "",
  uuid: "",
  agentCode: null
})
const ok1 = ref(false), ok2 = ref(false)
const codeTime = ref(0)
const registerRef = ref()
const equalToPassword = (rule, value, callback) => {
  if (registerForm.value.password !== value) {
    callback(new Error("两次输入的密码不一致"));
  } else {
    callback();
  }
};
const registerRules = ref({
  email: [
    { required: true, trigger: "blur", message: "请输入您的邮箱" },
    { type: 'email', message: '请输入正确的邮箱', trigger: 'blur' }
  ],
  password: [
    { required: true, trigger: "blur", message: "请输入您的密码" },
    { min: 5, max: 20, message: '用户密码长度必须介于 5 和 20 之间', trigger: 'blur' }
  ],
  confirmPassword: [
    { required: true, trigger: "blur", message: "请再次输入您的密码" },
    { required: true, validator: equalToPassword, trigger: "blur" }
  ],
  code: [{ required: true, trigger: "change", message: "请输入验证码" }],
  emailCode: [{ required: true, trigger: "change", message: "请输入邮箱验证码" }],
  inviteCode: [{ required: true, trigger: "change", message: "请输入邀请码" }],
})

const timer = ref()
//挂载之前
onBeforeMount(() => {
  $app.$http.config.getConfigKey('sys_cs_url').then(res => {
    sys_cs_url.value = res.msg;
  })
  $app.$store.commit("setOpenMsg", false)
  getCode()
  body.classList.remove("bg-gray-100");
  timer.value = setInterval(() => {
    getCode()
  }, 1000 * 60 * 2)
})
watch(() => $app.$route.path, () => {
  clearInterval(timer.value);
})

//卸载之前
onBeforeUnmount(() => {
  body.classList.add("bg-gray-100");
})
const sys_cs_url = ref('')
const openUrl = () => {
  window.open(sys_cs_url.value)
}

const getCode = () => {
  $app.$http.login.getCodeImg().then(res => {
    codeUrl.value = "data:image/gif;base64," + res.img;
    registerForm.value.uuid = res.uuid;
  })
}

const getEmailCode = () => {
  if (registerForm.value.email.indexOf("@") == -1) return $app.$message({ message: "请输入有效邮箱", type: 'error' });
  if (!registerForm.value.inviteCode) return $app.$message({ message: "请输入有效邀请码", type: 'error' });
  if (registerForm.value.email && !codeTime.value) {
    codeTime.value = 60
    let codeInt = setInterval(() => {
      codeTime.value--
      if (codeTime.value == 0) clearInterval(codeInt)
    }, 1000);
    $app.$http.login.getCodeEmail({
      email: registerForm.value.email,
      inviteCode: registerForm.value.inviteCode
    }).then(res => {
      $app.$message({
        message: res.msg,
        type: res.code == 200 ? 'success' : 'error'
      });
    })
  }
}

const handleReg = () => {
  if (!ok1.value || !ok2.value) $app.$message({
    message: "请同意所有注册条款！",
    type: 'error'
  });
  else
    registerRef.value.validate((valid) => {
      if (valid) $app.$http.login.register(registerForm.value).then(res => {
        $app.$message({
          message: "注册成功，请登录！",
          type: 'success'
        });
        $app.$router.push({
          name: "SignIn"
        })
      }).catch(e => {
        getCode()
      })
    })
}
</script>
