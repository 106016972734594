<template>
  <div class="container-fluid">
    <div class="d-flex justify-content-between d-card">
      <div class="row w-50 card-item">
        <div class="col-12">
          <div class="card card-contet" style="height:calc(100vh - 250px);min-height: 660px;">
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-3 pt-0">
                <div class="font-weight-bolder">快速充值</div>
                <el-divider></el-divider>
                <div class="">
                  <div class="border-radius-lg bg-gradient-dark px-3 mb-3 py-4" style="position: relative;background-image: url(/img/curved14.ee4f00d8.jpg);overflow: hidden;">
                    <span class="mask card-mask"></span>
                    <p class="mb-0 text-white font-weight-bolder zindex">钱包余额：</p>
                    <p class="text-white text-3xl font-weight-bolder zindex">$ {{filterNumber(walletTotal) }}</p>
                  </div>
                </div>
                <div class="mt-4">
                  <div class="font-weight-bolder">充值金额</div>
                  <div class="mt-2">
                    <el-row :gutter="20">
                      <el-col :span="8" :xs="12" v-for="item in priceList" :key="item" style="cursor: pointer;">
                        <div class="mb-4 py-1 px-2" @click="currentRadioAmount(item)" style="border-radius: 2px;border: 2px solid #efefef;">
                          <el-radio v-model="targetAmountVal" :label="'$' + item" class="w-100 m-0"></el-radio>
                        </div>
                      </el-col>
                    </el-row>
                    <!-- <div class="tags mt-2 d-flex" style="flex-wrap: wrap;">
                      <div class="tag-item" v-for="(item, index) in priceList" :key="index"
                        style="width: 33%; padding: 0 10px 10px 0;">
                        <el-check-tag :checked="targetAmountVal == item" size="large" @change="currentRadioAmount(item)"
                          style="font-size: 18px;">${{ item }}</el-check-tag>
                      </div>
                    </div> -->
                  </div>
                </div>
                <div class="mt-4 price-input">
                  <el-input v-model="rechargeForm.targetAmount" placeholder="请输入充值金额" @input="changeTargetAmount"
                    size="large">
                    <template #prepend><span class="font-weight-bolder">$</span></template>
                  </el-input>
                  <!-- <div class="text-xs p-1 theme-color" style="margin-left: 50px;" v-if="targetAmountVal < minPay">充值金额必须大于或等于${{ filterNumber(minPay) }}</div> -->
                  <div class="mt-2 text-danger">注：最低充值${{ filterNumber(minPay) }}美元</div>
                </div>
                <div class="mt-6 d-flex">
                  <div class="w-40" v-if="parseFloat(rechargeForm.targetAmount) >= minPay">
                    <material-button class="mb-3 primary-but" variant="gradient" color="primary" fullWidth
                      @click="submitRechargeForm">立即充值</material-button>
                  </div>
                  <div class="w-40" v-else>
                    <material-button class="mb-3 disabled-but" :disabled="true" variant="gradient" color="dark" @click="handleTis" fullWidth >
                      立即充值
                    </material-button>
                  </div>
                  <div class="w-40 ms-4" @click="openCs">
                    <material-button class="mb-3 dark-but" variant="gradient" color="dark" fullWidth
                      >客服充值</material-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row w-50 card-item">
        <div class="col-12">
          <div class="card h-100">
            <!-- <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-gradient-img">
              <div class="border-radius-lg pt-4 pb-3">
                <h6 class="text-white text-capitalize ps-3 d-zindex" v-if="walletTotal">钱包余额：$ {{ walletTotal }}</h6>
              </div>
            </div> -->
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-3 pt-0">
                <div class="font-weight-bolder">充值明细</div>
                <el-divider></el-divider>
                <div class="mt-0">
                  <div class="mb-4 font-weight-bolder">支付方式</div>
                  <el-select v-model="rechargeForm.paymentId" placeholder="选择支付方式" size="large" @change="selectPayment">
                    <el-option v-for="item in paymentOptions" :key="item.id" :label="item.name" :value="item.id" />
                  </el-select>
                </div>
                <div class="mt-4">
                  <div class="mb-2 font-weight-bolder">支付明细</div>
                  <div class="pay-content">
                    <div class="mt-2">共计：<span v-if="rechargeForm.paymentAmount">{{ currencyFilter(rechargeForm.paymentCurrency) }} {{
                      parseFloat(rechargeForm.paymentAmount).toFixed(2) || 'USDT 0.00' }}</span>
                    </div>
                    <el-divider />
                    <div class="mb-3">充值金额：{{ currencyFilter(rechargeForm.targetCurrency) }}{{ rechargeForm.targetAmount ?
                      parseFloat(rechargeForm.targetAmount).toFixed(2) : '0.00' }}</div>
                    <div class="mb-3">服务费率：{{ vipInfo.cardServiceFeeCoefficient }}%</div>
                    <div class="mb-3">
                      <span>支付金额 = </span>
                      <span v-if="rechargeForm.exchangeRate !== 1">( </span>
                      <span>{{ currencyFilter(rechargeForm.targetCurrency) }}{{ rechargeForm.targetAmount ?
                        parseFloat(rechargeForm.targetAmount).toFixed(2) : '0.00' }}</span>
                      <span> + </span>
                      <span>{{ currencyFilter(rechargeForm.targetCurrency) }}{{ rechargeForm.targetAmount ?
                        parseFloat(rechargeForm.targetAmount).toFixed(2) : '0.00' }}</span>
                      <span> * </span>
                      <span>{{ vipInfo.cardServiceFeeCoefficient }}%</span>
                      <span v-if="rechargeForm.exchangeRate !== 1"> ) * {{ rechargeForm.exchangeRate }}</span>
                      <span> = </span>
                      <span v-if="rechargeForm.paymentAmount">{{ currencyFilter(rechargeForm.paymentCurrency) }}{{
                        parseFloat(rechargeForm.paymentAmount).toFixed(2) }}</span>
                    </div>
                  </div>
                </div>
                <div class="mt-6">
                  <div class="mb-1 font-weight-bolder">温馨提醒</div>
                  <div>转账金额请按 "<span class="font-weight-bolder">支付金额</span>" 为准，转账币种请按 "<span class="font-weight-bolder">支付币种</span>" 为准，生成订单后请到 "<span class="font-weight-bolder">充值记录</span>" 菜单上传转账流水号</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 支付弹窗 -->
    <rechargeDialog :rechargeId="rechargeForm.id" :paymentObj="paymentObj" :isRechargeNow="true"
      v-model="showRechargeDialog" @refreshWallet="refreshWallets" :close="false" />

  </div>
</template>

<script setup>
import rechargeDialog from '@/components/rechargeDialog.vue'
import MaterialButton from "@/components/MaterialButton.vue";
import { ref, getCurrentInstance, onMounted } from 'vue';
const $app = getCurrentInstance().proxy
const priceList = ref(['1000', '2000', '4000', '6000', '8000', '10000'])

// const bill = ref({})
// $app.$http.bill.sumBill().then(res => {
//   bill.value = res.data;
// })
const wallets = ref({}),
  walletTotal = ref(0.00),
  paymentOptions = ref({}),
  vipInfo = ref({}),
  rechargeForm = ref({
    targetCurrency: "USD",
    paymentCurrency: "USD",
    targetAmount: 1000, //预计到账 充值金额
  }),
  paymentObj = ref({}),
  exchangeRate = ref({}),
  minPay = ref(1000),
  targetAmountVal = ref(1000),
  showRechargeDialog = ref(false),
  sys_cs_url = ref("")

const
  openCs = () => {
    window.open(sys_cs_url.value)
  },
  submitRechargeForm = () => {
    if (rechargeForm.value.id) {
      showRechargeDialog.value = true;
    } else {
      if (parseFloat(rechargeForm.value.targetAmount) >= minPay.value) {
        $app.$http.walletRecharge.addWalletRecharge({
          "paymentId": rechargeForm.value.paymentId,
          "paymentCurrency": rechargeForm.value.paymentCurrency,
          // "paymentAmount": rechargeForm.value.paymentAmount,
          "targetAmount": rechargeForm.value.targetAmount,
          "targetCurrency": rechargeForm.value.targetCurrency
        }).then(res => {
          rechargeForm.value.id = res.data.id;
          //根据支付方式获取支付信息
          showRechargeDialog.value = true;
        })
      }
    }
  },
  currencyFilter = (val) => {
    if (val === "USD") {
      return "$";
    } else if (val === "CNY") {
      return "￥";
    } else {
      return val
    }
  },
  getWalletInfo = async () => {
    $app.$store.commit("getUser")
    // 超管钱包为空
    if ($app.$store.state.user.tenantId) {
      // 获取钱包信息
      wallets.value = (await $app.$http.wallet.getWalletByCurrency("USD")).data;
      walletTotal.value = wallets.value.balance + wallets.value.frozenAmount;
    }
  },
  //充值刷新钱包
  refreshWallets = () => {
    getWalletInfo();
    $app.$router.push({ name: "rechargeOrder", query:{ key:'钱包充值' } })
  },
  initRecharge = async () => {
    vipInfo.value = (await $app.$http.shop.getApiCardVipSubscription()).data;
    //支付方式选项
    $app.$http.walletPayment.listWalletPayment({ status: 0 }).then(res => {
      paymentOptions.value = res.rows;
      rechargeForm.value.paymentId = paymentOptions.value[0].id;
      rechargeForm.value.paymentType = paymentOptions.value[0].type;
      paymentObj.value = paymentOptions.value[0];
      if (paymentObj.value.type === 'USDT') {
        rechargeForm.value.paymentCurrency = 'USDT'
      }
      // 获取汇率 美元至 人民币的汇率
      $app.$http.currencyExchange.getCurrencyExchangeBySourceCurrencyAndTargetCurrency(rechargeForm.value.targetCurrency, rechargeForm.value.paymentCurrency).then(res => {
        rechargeForm.value.exchangeRate = res.data.rate
        changeTargetAmount();
      })
    })
  },
  currentRadioAmount = (amount) => {
    targetAmountVal.value = amount;
    rechargeForm.value.id = null
    rechargeForm.value.targetAmount = targetAmountVal.value;
    changeTargetAmount();
  },
  changeTargetAmount = () => {
    let targetAmount = parseFloat(rechargeForm.value.targetAmount)
    if (isNaN(rechargeForm.value.targetAmount)) {//输入含非数值
      rechargeForm.value.targetAmount = targetAmount ? targetAmount : null;
      return;
    }
    if (targetAmount || targetAmount === 0) {
      let fee_rate = parseFloat((vipInfo.value.cardServiceFeeCoefficient / 100).toFixed(10));
      let service_fee = parseFloat((targetAmount * fee_rate).toFixed(2));
      let paymentAmount = ((targetAmount + service_fee) * rechargeForm.value.exchangeRate).toFixed(2);
      rechargeForm.value.paymentAmount = paymentAmount
    } else {
      rechargeForm.value.targetAmount = ''
      rechargeForm.value.paymentAmount = 0
    }

    targetAmountVal.value = parseFloat(rechargeForm.value.targetAmount);
  },
  //支付方式选择
  selectPayment = () => {
    paymentObj.value = this.paymentOptions.find(res => res.id === rechargeForm.value.paymentId);
    if (paymentObj.value.type === 'USDT') {
      rechargeForm.value.paymentCurrency = 'USDT'
    } else {
      rechargeForm.value.paymentCurrency = 'USD'
    }

    $app.$http.currencyExchange.getCurrencyExchangeBySourceCurrencyAndTargetCurrency(rechargeForm.value.targetCurrency, rechargeForm.value.paymentCurrency).then(res => {
      rechargeForm.value.exchangeRate = res.data.rate
      changeTargetAmount();
    })
  }

onMounted(() => {
  getWalletInfo()
  initRecharge()
  $app.$http.config.getConfigKey('sys_cs_url').then(res => {
    sys_cs_url.value = res.msg;
  })
})
</script>
<style lang="scss">

.pay-content {
  padding: 10px;
  border-radius: 5px;
  background-color: #F5F7FA;
  border: 2px solid #efefef;
}
.card-mask{
  background-image: linear-gradient(195deg, #bcc2cf 0%, #333333 100%);
  // width: 104%;
  // height: 104%;
  // margin: -2% 0 0 -2%;
}
.price-input{
  width: 207px;
}
@media screen and (max-width: 600px) {
  .d-card{
    flex-wrap: wrap !important;
    .card-item{
      width: 100% !important;
      --bs-gutter-x: 0 !important;
      margin-bottom: 2rem;
      .card-contet{
        height: auto !important;
      }
    }
  }
}
</style>
