/*eslint-disable*/
import axios from 'axios';
import {
  ElMessage,
  ElMessageBox
} from 'element-plus'
import store from "../store";
import router from "../router";
import errorCode from '@/utils/errorCode'

// 创建一个Axios实例
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // 设置请求的基础URL
  timeout: 600000, // 设置请求超时时间
});

// 设置请求拦截器
service.interceptors.request.use(
  (config) => {
    // 在请求发送之前做一些处理，例如添加请求头等
    if (["/captchaImage", "/login", "/register", "/retrieve", "/captchaEmail"].indexOf(config.url) == -1) {
      if (store.state.token) config.headers.Authorization = store.state.token
    }
    if (store.state.safeKey) {
      config.headers['SAFE-KEY'] = store.state.safeKey
    }
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?';
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof (value) !== "undefined") {
          if (typeof value === 'object') {
            for (const key of Object.keys(value)) {
              if (value[key] !== null && typeof (value[key]) !== 'undefined') {
                let params = propName + '[' + key + ']';
                let subPart = encodeURIComponent(params) + '=';
                url += subPart + encodeURIComponent(value[key]) + '&';
              }
            }
          } else {
            url += part + encodeURIComponent(value) + "&";
          }
        }
      }
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    return config;
  },
  (error) => {
    // 处理请求错误
    return Promise.reject(error);
  }
);

// 设置响应拦截器
service.interceptors.response.use(
  (response) => {
    // 未设置状态码则默认成功状态
    const code = response.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || response.data.msg || errorCode['default']
    // 在响应数据之前做一些处理
    if (code == 500) {
      ElMessage.warning(msg)
      return Promise.reject(new Error(msg))
    } else if (code == 401) {
      store.commit("setIsOut", true)
      store.state.isOut = true
      if (!window.openMsg) {
        window.openMsg = true
        // store.commit("setOpenMsg", true)
        ElMessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          // showCancelButton: false,
          showClose: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          type: 'warning'
        }).then(() => {
          store.commit("logOut")
          window.openMsg = false
        }).catch(() => {
          window.openMsg = false
        });
      }
      // setTimeout(() => {
      //   store.commit("setOpenMsg", false)
      // }, 3000)
      // ElMessage.error(response.data.msg)
      return Promise.reject(new Error(msg))
    } else if (code == 409) {
      store.commit("setIsOut", true)
      store.state.isOut = true
      if (!window.openMsg) {
        window.openMsg = true
        // store.commit("setOpenMsg", true)
        ElMessageBox.confirm('系统检测您的网络环境发生变化，登录状态已失效，您可以继续留在该页面，或者重新登录', '系统提示', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          // showCancelButton: false,
          showClose: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          type: 'warning'
        }).then(() => {
          store.commit("logOut")
          window.openMsg = false
        }).catch(() => {
          window.openMsg = false
        });
      }
      // setTimeout(() => {
      //   store.commit("setOpenMsg", false)
      // }, 3000)
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (code == 408) {
      ElMessageBox.confirm(msg, '系统提示', {
        confirmButtonText: '查看欠款记录',
        cancelButtonText: '留在当前页',
        type: 'warning'
      }).then(() => {
        router.push({
          name: "CardList"
        })
      }).catch(() => {});
      return Promise.reject(new Error(msg))
    } else if (code != 200) {
      ElMessage.warning(msg)
      return Promise.reject(new Error(msg))
    }
    return response.data;
  },
  (error) => {
    // 处理响应错误
    return Promise.reject(error);
  }
);

// 封装请求方法
// const request = {
//   get: (url, params) => service.get(url, { params }),
//   post: (url, data) => service.post(url, data),
//   // 可以根据需要添加其他HTTP请求方法，例如PUT、DELETE等
// };

export default service;