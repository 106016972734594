<template>
  <div>
    <noPower v-if="!$store.state.user.userLevel"></noPower>
    <div class="container-fluid" v-else>
      <div class="card card-body">
        <div class="d-flex align-items-center justify-content-between flex-wrap">
          <div class="d-flex">
            <div>
              <!-- <div class="avatar avatar-xl position-relative">
              <img
                src="@/assets/img/avatar/FxemojiGem.png"
                alt="profile_image"
                class="shadow-sm w-100 border-radius-lg"
              />
            </div> -->
              <div class="avatar avatar-xl position-relative">
                <img class="w-100" :src="require('@/assets/img/avatar/Level' + $store.state.user.userLevel + '.svg')">
              </div>
              <div class="text-xl font-weight-bolder text-center">{{ userInfo.cardVipName }}</div>
            </div>
            <div class="mx-3">
              <div class="h-100">
                <div class="d-flex flex-wrap">
                  <h5 class="mb-1">{{ $store.state.user.nickName }} , {{ timeText }}
                    <el-tag size="large" :type="userInfo.cardVipId > 1 ? 'warning' : 'info'" class="mx-3">
                    {{ userInfo.cardVipName }}
                    </el-tag>
                  </h5>
                </div>
                <p class="mb-0 font-weight-normal text-sm mt-2">
                  <el-tag size="large" type="info" v-if="userInfo.cardVipId > 1">
                    到期时间：{{ userInfo.expireDate }}
                  </el-tag>
                </p>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div style="width: 100px;" @click="changePassword" class="pt-1 pb-1 mx-3">
              <material-button class="dark-but" variant="gradient" color="dark" fullWidth>修改密码</material-button>
            </div>
            <div style="width: 100px;" class="pt-1 pb-1">
              <router-link :to="{ name: 'CardOrder' }">
                <material-button class="dark-but" variant="gradient" color="dark" fullWidth>开卡记录</material-button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <div class="nav-wrapper position-relative end-0 d-flex flex-wrap">
            <div class="mini-card" style="margin-right: 10px;background: #f9f9f9;">
              <span class="font-weight-bolder">账号余额</span><br>
              <span>${{ filterNumber(userInfo.balance) }}</span>
            </div>
            <!-- <div class="mini-card" style="margin-right: 10px;background: #f9f9f9;">
            <span>冻结金额</span><br>
            <span>${{ userInfo.frozenAmount }}</span>
          </div> -->
            <div class="mini-card" style="margin-right: 10px;background: #f9f9f9;">
              <span class="font-weight-bolder">剩余卡位</span><br>
              <span>{{ formatUnlimited(userInfo.cardCapacity) }}</span>
            </div>
            <div class="mini-card" style="margin-right: 10px;background: #f9f9f9;">
              <span class="font-weight-bolder">开卡费</span><br>
              <span v-if="userInfo.bigOpenFee === 0 && userInfo.smallOpenFee === 0">$0.00/张</span>
              <span v-else>${{ filterNumber(userInfo.bigOpenFee) }} - ${{ filterNumber(userInfo.smallOpenFee) }}/张</span>
            </div>
            <div class="mini-card" style="background: #f9f9f9;">
              <span class="font-weight-bolder">服务费率</span><br>
              <span>{{ filterNumber(userInfo.cardServiceFeeCoefficient) }}%</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card card-body mt-3">
        <div class="u-flex u-row-between">
          <div class="u-flex">
            <i class="u-font-30 font-bold"
              :class="userInfo.allowRemoteOperation ? 'text-warning el-icon-warning-outline' : 'text-navy el-icon-circle-check'" />
            <div class="u-p-lr-15 u-font-14">
              <div class="u-font-16 font-bold u-p-b-8">
                <span class="font-weight-bolder">操作环境保护</span>
                <span :class="userInfo.allowRemoteOperation ? 'text-warning' : 'text-success'">
                  [{{ userInfo.allowRemoteOperation ? '未开启' : '保护中' }}]
                </span>
              </div>
              <div class="text-muted">
                系统将实时检测您登录后的操作网络环境，如发现您的网络环境发生变化将阻止其所有操作，同时登录状态失效。
              </div>
              <div class="text-muted">
                如果您的业务场景需要频繁变更网络环境，或不希望网络环境变更后需重新登录系统，可关闭此功能。
              </div>
            </div>
          </div>
          <el-button class="mt-1" :type="userInfo.allowRemoteOperation ? 'success' : 'info'" plain
            @click="changeOperationProtection">
            {{ userInfo.allowRemoteOperation ? '开启保护' : '关闭保护' }}
          </el-button>
        </div>
      </div>
      <div class="card card-body mt-3">
        <div class="u-flex u-row-between">
          <div class="u-flex">
            <i class="u-font-30 font-bold"
              :class="userInfo.isSafePassword ? 'text-navy el-icon-circle-check' : 'text-warning el-icon-warning-outline'" />
            <div class="u-p-lr-15 u-font-14">
              <div class="font-weight-bolder">操作密码<span class="text-warning u-p-l-5"
                  v-if="!userInfo.isSafePassword">[未设置]</span></div>
              <div class="text-muted">
                您在交易操作（如：开卡、卡片充值/导出/注销）时，通过验证操作密码再次确认您的身份。
              </div>
            </div>
          </div>
          <div class="mt-1">
            <el-button type="info" plain @click="delPwdSafe" v-if="userInfo.isSafePassword">关闭操作密码</el-button>
            <el-button :type="userInfo.isSafePassword ? 'primary' : 'success'" plain @click="showChangeSafePwd = true">{{
              userInfo.isSafePassword ? '修改密码' : '马上设置' }}</el-button>
          </div>
        </div>
      </div>
      <div class="card card-body mt-3">
        <div class="u-flex u-row-between">
          <div class="u-flex">
            <i class="u-font-30 font-bold"
              :class="userInfo.switch2fa && userInfo.isSecret2fa ? 'text-navy el-icon-circle-check' : 'text-warning el-icon-warning-outline'" />
            <div class="u-p-lr-15 u-font-14">
              <div class="u-font-16 font-bold u-p-b-8 font-weight-bolder">双因素身份验证(2FA)<span class="text-warning u-p-l-5"
                  v-if="!userInfo.switch2fa">[未启用]</span></div>
              <div class="text-muted">
                <span class="u-p-r-3">身份验证器应用程序和浏览器扩展（例如</span>
                <el-link href="https://support.1password.com/one-time-passwords" target="_blank"
                  type="primary">1Password</el-link>
                <span>、</span>
                <el-link href="https://www.microsoft.com/en-us/security/mobile-authenticator-app" target="_blank"
                  type="primary">Microsoft Authenticator</el-link>
                <span>、</span>
                <el-link href="https://authy.com/guides/github" target="_blank" type="primary">Authy</el-link>
                <span class="u-p-l-3">等) 会生成一次性密码，这些密码用作在登录过程中出现提示时验证您身份的第二个因素。</span>
              </div>
            </div>
          </div>
          <div v-if="userInfo.isSecret2fa">
            <el-button :type="userInfo.switch2fa ? 'info' : 'success'" plain @click="handleChangeSwitch2fa">{{
              userInfo.switch2fa ? '关闭验证' : '开启验证' }}</el-button>
          </div>
          <div v-else>
            <el-button type="success" plain @click="handleShow2fa">马上启用</el-button>
          </div>
        </div>
      </div>
      <el-dialog title="修改密码" v-model="showChangePsw" :before-close="pswClose" width="600px">
        <div class="change-psw-content">
          <el-form ref="formRules" :model="pswForm" :rules="pswRules" label-width="80px">
            <el-form-item label="旧密码" prop="oldPassword">
              <el-input v-model="pswForm.oldPassword" placeholder="请输入旧密码" type="password" show-password />
            </el-form-item>
            <el-form-item label="新密码" prop="newPassword">
              <el-input v-model="pswForm.newPassword" placeholder="请输入新密码" type="password" show-password />
            </el-form-item>
            <el-form-item label="确认密码" prop="confirmPassword">
              <el-input v-model="pswForm.confirmPassword" placeholder="请确认密码" type="password" show-password />
            </el-form-item>
          </el-form>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="pswClose">取 消</el-button>
            <el-button type="primary" @click="pswClick">确 定</el-button>
          </span>
        </template>
      </el-dialog>

      <el-dialog title="修改资料" v-model="showChangeUserInfo" width="600px" @close="userClose">
        <div class="change-user-content">
          <el-form ref="userFormRules" :model="userForm" :rules="userRules" label-width="100px">
            <el-form-item label="用户昵称" prop="nickName">
              <el-input v-model="userForm.nickName" maxlength="30" />
            </el-form-item>
            <el-form-item label="性别">
              <el-radio-group v-model="userForm.sex">
                <el-radio label="0">男</el-radio>
                <el-radio label="1">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="userClose">取 消</el-button>
            <el-button type="primary" @click="userClick">确 定</el-button>
          </span>
        </template>
      </el-dialog>

      <!--  修改操作密码  -->
      <el-dialog title="修改操作密码" v-model="showChangeSafePwd" width="500px" @close="pwdSafeClose">
        <div class="change-pwd-content">
          <el-form ref="formPwd" :model="pwdForm" :rules="pwdRules" label-width="80px">
            <el-form-item label="旧密码" prop="oldPassword" v-if="userInfo.isSafePassword">
              <el-input v-model="pwdForm.oldPassword" placeholder="请输入旧密码" type="password" show-password />
            </el-form-item>
            <el-form-item label="新密码" prop="newPassword">
              <el-input v-model="pwdForm.newPassword" placeholder="请输入新密码" type="password" show-password />
              <div class="text-muted u-font-12 u-p-t-4" style="line-height: 18px">
                建议设置一个包含字母，符号或数字中至少两项且长度超过6位的密码
              </div>
            </el-form-item>
            <el-form-item label="确认密码" prop="confirmPassword">
              <el-input v-model="pwdForm.confirmPassword" placeholder="请确认密码" type="password" show-password />
            </el-form-item>
          </el-form>
        </div>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="pwdSafeClose">取 消</el-button>
            <el-button type="primary" @click="pwdSafeClick">确 定</el-button>
          </span>
        </template>
      </el-dialog>

      <!--  启用2FA  -->
      <el-dialog title="启用双因素身份验证(2FA)" v-model="showBind2fa" width="600px" @close="handle2faClose"
        :close-on-click-modal="false">
        <div class="change-pwd-content">
          <el-form ref="bind2faFormRef" :model="bind2faForm" :rules="bind2faRules" label-position="top">
            <el-form-item label="设置验证器应用程序" class="u-form-item-ps-label">
              <div class="u-font-14 u-form-item-ps-lh">
                <span class="u-p-r-3">身份验证器应用程序和浏览器扩展（例如</span>
                <el-link href="https://support.1password.com/one-time-passwords" target="_blank"
                  type="primary">1Password</el-link>
                <span>、</span>
                <el-link href="https://www.microsoft.com/en-us/security/mobile-authenticator-app" target="_blank"
                  type="primary">Microsoft Authenticator</el-link>
                <span>、</span>
                <el-link href="https://authy.com/guides/github" target="_blank" type="primary">Authy</el-link>
                <span class="u-p-l-3">等) 会生成一次性密码，这些密码用作在登录过程中出现提示时验证您身份的第二个因素。</span>
              </div>
            </el-form-item>
            <el-form-item label="扫描二维码" class="u-form-item-ps-label" style="display: block;">
              <div class="u-form-item-ps-lh">使用身份验证器应用程序或浏览器扩展进行扫描。</div>
              <div class="u-p-t-10"><img :src="bind2faForm.img" alt="" /></div>
              <div class="u-form-item-ps-lh">无法扫描? 您可以使用 <el-link type="primary" @click="show2faKey">设置key</el-link>
                手动配置您的身份验证器应用程序。</div>
            </el-form-item>
            <el-form-item label="验证应用程序中的代码" prop="code">
              <el-input v-model="bind2faForm.code" placeholder="XXXXXX" />
            </el-form-item>
          </el-form>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="handle2faClose">取 消</el-button>
            <el-button type="primary" @click="handle2faSubmit">确 定</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script setup>
import noPower from '@/components/noPower'
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import MaterialButton from "@/components/MaterialButton.vue";
import { ref, getCurrentInstance, onMounted, onBeforeUnmount } from "vue";
const $app = getCurrentInstance().proxy

onMounted(() => {
  if (!$app.$store.state.user.userLevel) return
  $app.$store.state.isAbsolute = true;
  $app.$http.shop.getApiCardVipSubscription().then(res => {
    userInfo.value.expireDate = res.data.expireDate
  })
  setNavPills();
  setTooltip();
  //
  getTimeText();
  getUserVipInfo()
  getInfo()
})
onBeforeUnmount(() => {
  $app.$store.state.isAbsolute = false;
})

const
  noticeText = ref("美元充值-收款账户信息变更条款通知"),
  timeText = ref(''),
  userInfo = ref({
    cardVipId: null, //会员名
    cardVipName: null, //会员名
    cardCapacity: 0, //卡位
    bigOpenFee: 0, //开卡费
    smallOpenFee: 0, //开卡费
    cardServiceFeeCoefficient: 0, //服务费率
    rechargeLimit: 0, //充值限额
    avatar: null,
    balance: null,
    frozenAmount: 0,
    expireDate: null,
  }),
  vipCardList = ref([]),
  imageUrl = ref(''),
  // 上传参数
  upload = ref({
    // 是否禁用上传
    isUploading: false,
    // 设置上传的请求头部
    headers: {
      Authorization: "Bearer " + $app.$store.state.token
    },
    url: process.env.VUE_APP_BASE_API + "/common/upload",
    // 上传的文件列表
    fileList: []
  }),
  showChangePsw = ref(false),
  pswForm = ref({
    oldPassword: null,
    newPassword: null,
    confirmPassword: null,
  }),
  equalToPassword = (rule, value, callback) => {
    if (value !== pswForm.value.newPassword) {
      callback(new Error("两次输入的密码不一致"));
    } else {
      callback();
    }
  },
  pswRules = ref({
    oldPassword: [{
      required: true,
      message: "旧密码不能为空",
      trigger: "blur"
    }],
    newPassword: [{
      required: true,
      message: "新密码不能为空",
      trigger: "blur"
    },
    {
      min: 6,
      max: 20,
      message: "长度在 6 到 20 个字符",
      trigger: "blur"
    }
    ],
    confirmPassword: [{
      required: true,
      message: "确认密码不能为空",
      trigger: "blur"
    },
    {
      required: true,
      validate: equalToPassword,
      trigger: "blur"
    }
    ]
  }),
  formRules = ref(''),
  showChangeUserInfo = ref(false),
  userForm = ref({
    nickName: null,
    sex: null
  }),
  userFormRules = ref(''),
  userRules = ref({
    nickName: [{
      required: true,
      message: "用户昵称不能为空",
      trigger: "blur"
    }],
  }),
  formPwd = ref(),
  showChangePwd = ref(false),
  showChangeSafePwd = ref(false),
  pwdForm = ref({
    oldPassword: null,
    newPassword: null,
    confirmPassword: null,
  }),
  pwdRules = ref({
    oldPassword: [{ required: true, message: "旧密码不能为空", trigger: "blur" }],
    newPassword: [
      { required: true, message: "新密码不能为空", trigger: "blur" },
      { min: 6, max: 20, message: "长度在 6 到 20 个字符", trigger: "blur" }
    ],
    confirmPassword: [
      { required: true, message: "确认密码不能为空", trigger: "blur" },
      {
        required: true, validator: (rule, value, callback) => {
          if (value !== pwdForm.value.newPassword) {
            callback(new Error("两次输入的密码不一致"));
          } else {
            callback();
          }
        }, trigger: "blur"
      }
    ]
  }),
  showBind2fa = ref(false),
  bind2faForm = ref({
    secret: null,
    code: null,
    img: null
  }),
  bind2faRules = ref({
    code: [
      { required: true, message: "请输入验证应用程序中的代码", trigger: "blur" },
      {
        required: true, validator: (rule, value, callback) => {
          if (value && value.length >= 6) {
            $app.$http.user.checkGoogleValidCode(bind2faForm.value.secret, value).then(res => {
              if (res.data) {
                callback();
              } else {
                callback(new Error("2FA代码验证失败。请再试一次"));
              }
            })
          } else {
            callback();
          }
        }, trigger: "blur"
      }
    ]
  }),
  bind2faFormRef = ref()


const
  //修改密码
  changePassword = () => {
    showChangePsw.value = true;
  },
  pswClose = () => {
    showChangePsw.value = false;
  },
  pswClick = () => {
    formRules.value.validate(valid => {
      if (valid) {
        $app.$http.user.updateUserPwd(pswForm.value.oldPassword, pswForm.value.newPassword).then(res => {
          if (res.code == 200) {
            $app.$message.success("修改成功");
            pswClose();
          }
        });
      }
    });
  },
  userClose = () => {
    showChangeUserInfo.value = false
  },
  //修改用户信息
  changeUserInfo = () => {
    userForm.value.nickName = $app.$store.state.user.nickName;
    userForm.value.sex = $app.$store.state.user.sex;
    showChangeUserInfo.value = true;
  },
  userClick = () => {
    userFormRules.value.validate(valid => {
      if (valid) {
        $app.$http.user.updateUserProfile(userForm.value).then(res => {
          $app.$message.success("修改成功");
          userClose();
        });
      }
    })
  },
  getTimeText = () => {
    let date = new Date();
    let h = date.getHours();
    if (6 <= h && h < 11) {
      timeText.value = "早上好"
    } else if (11 <= h && h < 14) {
      timeText.value = "中午好"
    } else if (14 <= h && h < 19) {
      timeText.value = "下午好"
    } else if (19 <= h && h < 24) {
      timeText.value = "晚上好"
    } else {
      timeText.value = "你好"
    }
  },
  getUserVipInfo = () => {
    $app.$http.shop.getApiCardVipSubscription().then(res => {
      let data = res.data;
      userInfo.value.cardVipId = data.cardVipId;
      userInfo.value.cardVipName = data.cardVipName;
      userInfo.value.cardCapacity = data.cardCapacity;
      userInfo.value.cardServiceFeeCoefficient = data.cardServiceFeeCoefficient;
      vipCardList.value = data.cardProductList;
      getOpenFeeRange();
    })
  },
  getOpenFeeRange = () => {
    userInfo.value.bigOpenFee = 0;
    userInfo.value.smallOpenFee = vipCardList.value[0].price;
    vipCardList.value.map(item => {
      userInfo.value.bigOpenFee = item.price >= userInfo.value.bigOpenFee ? item.price : userInfo.value.bigOpenFee;
      userInfo.value.smallOpenFee = item.price <= userInfo.value.smallOpenFee ? item.price : userInfo.value.smallOpenFee;
    })
  },
  getInfo = () => {
    $app.$http.login.getInfo().then(res => {
      userInfo.value = res.user
      userInfo.value.avatar = res.user.avatar ? 'https://cardsapi.ITTCARD.com' + res.user.avatar : res.user.avatar
      $app.$store.commit("setUser", res.user)
      $app.$store.commit("setPermissions", res.permissions)
    })
  },
  // 文件上传中处理
  handleFileUploadProgress = (event, file, fileList) => {
    upload.value.isUploading = true;
  },
  // 文件上传成功处理
  handleAvatarSuccess = (response, file, fileList) => {
    upload.value.isUploading = false;
    userInfo.value.avatar = response.fileName;
    $app.$message.success(response.msg);
  },
  beforeAvatarUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < 5;
    if (!isLt1M) {
      $app.$message.error('文章缩略图大小不能超过 5MB!');
    }
    return isLt1M;
  }
const
  formatUnlimited = (val, unit) => {
    if (val != null && Number(val.toString().replace(/[^0-9.]+/, '')) > 0) {
      return val + (unit ? unit : '') + '（可增加）'
    }
    return val == null ? '无限制' : val;
  },
  simpleAmount = (val) => {
    if (val >= 1000) {
      return val / 1000 + 'K';
    }
    return val;
  },
  pwdSafeClose = () => {
    formPwd.value.resetFields();
    showChangeSafePwd.value = false;
  },
  pwdSafeClick = () => {
    formPwd.value.validate(valid => {
      if (valid) {
        $app.$http.user.updateUserSafePwd(pwdForm.value.oldPassword, pwdForm.value.newPassword).then(() => {
          $app.$message.success("修改成功");
          pwdSafeClose();
          getInfo()
        }
        );
      }
    });
  },
  delPwdSafe = async () => {
    if (!await $app.verifySafePwd()) return
    $app.$confirm('是否确认关闭操作密码？').then(() => {
      return $app.$http.user.delUserSafePwd();
    }).then(() => {
      getInfo()
      $app.$message.success("关闭操作密码成功");
    }).catch(() => {
    });
  },
  // 修改操作保护
  changeOperationProtection = () => {
    let msg = userInfo.value.allowRemoteOperation ? '是否确认开启操作环境保护？开启后系统将实时检测您登录后的操作网络环境！如发现异常将阻止所有操作同时退出登录' :
      '是否确认关闭操作环境保护？关闭后系统将不再检测您登录后的操作网络环境！'

    $app.$confirm(msg).then(() => {
      return $app.$http.user.changeRemoteOperation(!userInfo.value.allowRemoteOperation);
    }).then(() => {
      getInfo()
      $app.$message.success((userInfo.value.allowRemoteOperation ? "开启" : "关闭") + "操作环境保护成功");
    }).catch(() => {
    });
  },
  handleShow2fa = () => {
    $app.$http.user.getSecretKey2fa().then(res => {
      bind2faForm.value.img = res.img;
      bind2faForm.value.secret = res.key;
      showBind2fa.value = true;
    })
  },
  show2faKey = () => {
    $app.$alert(bind2faForm.value.secret, '你的双重密钥', { showConfirmButton: false });
  },
  handle2faClose = () => {
    bind2faForm.value = {
      secret: null,
      code: null,
      img: null
    }
    showBind2fa.value = false;
  },
  handle2faSubmit = () => {
    bind2faFormRef.value.validate(valid => {
      if (valid) {
        $app.$http.user.bindUserSecretKey2fa(bind2faForm.value.secret, bind2faForm.value.code).then(res => {
          getInfo();
          handle2faClose();
          $app.$message.success("启用双因素身份验证(2FA)成功");
        })
      }
    });
  },
  handleChangeSwitch2fa = () => {
    let msg = '是否确认' + (userInfo.value.switch2fa ? '关闭' : '开启') + '双因素身份验证(2FA)?'
    $app.$confirm(msg).then(() => {
      return $app.$http.user.changeSwitch2fa(!userInfo.value.switch2fa);
    }).then(() => {
      getInfo();
      $app.$message.success((userInfo.value.switch2fa ? "关闭" : "开启") + "双因素身份验证(2FA)成功");
    }).catch(() => {
    });
  }
</script>

<style lang="scss">
.client-secure-container{
  .el-link{
    vertical-align: initial;
  }
  .u-form-item-ps-lh{
    line-height: 20px;
  }
  .u-form-item-ps-label{
    color: #888888;
    .el-form-item__label{
      padding-bottom: 0;
    }
  }
}
.mini-card {
  background: #fff;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #e4e7ed;
  width: 20%;
}

.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 74px;
  height: 74px;
  text-align: center;
}
.w80{
  width: 80%;
}
@media screen and (max-width: 600px) {
  .w80{
    width: 100% !important;
  }
  .mini-card{
    width: 100%;
  }
}
</style>
