import {
  createRouter,
  createWebHistory
} from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Recharge from "../views/Recharge.vue";
import Notifications from "../views/Notifications.vue";
import Profile from "../views/Profile.vue";
import SignIn from "../views/SignIn.vue";
import SignUp from "../views/SignUp.vue";
import Layout from '../layout/index.vue'
import Card from '../views/Card.vue'
import CardOpen from '../views/CardOpen.vue'

const routes = [{
    path: '',
    redirect: '/dashboard'
  }, {
    component: Layout,
    children: [{
        path: "/dashboard",
        component: Dashboard,
        name: 'Dashboard',
        meta: {
          title: '首页'
        }
      },
      {
        path: "/CardOrder",
        component: () => import("../views/CardOrder.vue"),
        name: 'CardOrder',
        meta: {
          title: '开卡记录'
        }
      },
      {
        path: "/recharge",
        component: Recharge,
        name: 'Recharge',
        meta: {
          title: '快速充值'
        }
      },
      {
        path: "/rechargeOrder",
        component: () => import("../views/rechargeOrder.vue"),
        name: 'rechargeOrder',
        meta: {
          title: '充值订单'
        }
      },
      {
        path: "/cardList",
        component: () => import("../views/CardList.vue"),
        name: 'CardList',
        meta: {
          title: '我的卡片'
        }
      },
      {
        path: "/cardrecord",
        component: () => import("../views/CardRecord.vue"),
        name: 'CardRecord',
        meta: {
          title: '交易记录'
        }
      },
      {
        path: "/finance",
        component: () => import("../views/Finance.vue"),
        name: 'Finance',
        meta: {
          title: '财务明细'
        }
      },
      {
        path: "/card",
        component: Card,
        name: 'Card',
        meta: {
          title: '卡片/会员'
        }
      },
      {
        path: "/CardOpen",
        component: CardOpen,
        name: 'CardOpen',
        meta: {
          title: '快速开卡'
        }
      },
      {
        path: "/notifications",
        component: Notifications,
        name: 'Notifications',
        meta: {
          title: '消息中心'
        }
      },
      {
        path: "/ApiView",
        component: () => import("../views/ApiView.vue"),
        name: 'ApiView',
        meta: {
          title: '开发API'
        }
      },
      {
        path: "/profile",
        component: Profile,
        name: 'Profile',
        meta: {
          title: '账户/安全'
        }
      },
      {
        path: "/operate",
        component: () => import("../views/Operate.vue"),
        name: 'Operate',
        meta: {
          title: '操作记录'
        }
      }
    ]
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/clause",
    name: "clause",
    component: () => import("../views/clause.vue"),
  },
  {
    path: "/:pathMatch(.*)",
    redirect: '/dashboard'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;